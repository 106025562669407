type DefaultCurrency = {
  isoCode: string;
  symbol: string;
};

export const priceFormat = (
  price: number,
  currency?: string,
  showCurrencyAs?: 'symbol' | 'letter',
  fractionDigitsAmount = 2,
) => {
  let mode = showCurrencyAs ?? 'symbol';
  try {
    const formattedPrice = Intl.NumberFormat('ru-RU', {
      minimumFractionDigits: fractionDigitsAmount,
      maximumFractionDigits: fractionDigitsAmount,
    }).format(price);

    if (currency && mode === 'letter') {
      return `${formattedPrice} ${currency}`;
    }

    return currency
      ? new Intl.NumberFormat('ru-RU', {
          style: 'currency',
          currency,
          minimumFractionDigits: fractionDigitsAmount,
          maximumFractionDigits: fractionDigitsAmount,
        }).format(price)
      : formattedPrice;
  } catch (e) {
    return price.toString();
  }
};

export const priceFormatWithoutCurrency = (price: number, defaultCurrency: DefaultCurrency) => {
  return priceFormat(price, defaultCurrency.isoCode).replace(defaultCurrency.symbol, '');
};

export const getCurrencySymbol = (defaultCurrency: DefaultCurrency, currency?: string) => {
  const _currency = currency || defaultCurrency.isoCode;
  try {
    return (0)
      .toLocaleString('ru-RU', {
        style: 'currency',
        currency: _currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  } catch (e) {
    return defaultCurrency.symbol;
  }
};

export const priceFormatWithoutCurrencyAndWithShortener = (
  price: number,
  defaultCurrency: DefaultCurrency,
) => {
  if (price === 0) return '0';

  if (price < 1000) {
    return priceFormatWithoutCurrency(price, defaultCurrency);
  }

  if (price < 1_000_000) {
    return formatRoundedValue(price / 1000, 'K');
  }

  return formatRoundedValue(price / 1_000_000, 'M');
};

const formatRoundedValue = (value: number, suffix: string): string => {
  const roundedValue = Math.round(value * 100) / 100;
  return `${roundedValue}${suffix}`.replace(/\.?0+$/, '');
};
